.portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* height: 70vh; */

}
/* heading */
.portfolio span:nth-of-type(1) {
  color: var(--black);
  font-size: 2rem;
  font-weight: bold;
}

.portfolio span:nth-of-type(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}

/* slider */

.project-button{
  /* width: 19rem/; */

background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
box-shadow: none;
border-radius: 34px;
border: none;
color: white;
font-size: 16px;
padding: 11px 26px 11px 26px;
}

/* test */
/* .r-container{
  overflow: hidden;
  position: relative;
} */
.r-head{
  margin-bottom: 2rem;
 
}
.r-card{
 
  gap: 0.6rem;
  padding: 1rem;
  border-radius: 10px;
 max-width: max-content; 
 
  margin: auto;
  transition: all 300ms ease-in;
   /* background-color: #2c73ee; */
    border:1px solid rgba(224, 224, 224, 0.808); 
}
.r-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.r-card>img{
  width: 100%;
  max-width: 15rem;
  border-radius: 8px;
  height:200px ;
  

}
.s-blur2 {
  left: 14rem;
  top: 8rem;
}

.s-blur1 {
  top: 13rem;
  left: -18rem;
}
/* .r-card>:nth-child(2){
  font-size: 1.2rem;
  font-weight: 600;
}
.r-card>:nth-child(3){
  font-size: 1.5rem;
}
.r-card>:nth-child(4){
 font-size: 0.7rem;
 width: 15rem;
} */
.r-card:hover{
  scale: 1.025;
  cursor: pointer;
  background: linear-gradient(
      180deg,
      rgba(255,255,255,0) 0%,
      rgba(136,160,255,0.46) 217.91%
  );
  border:1px solid rgba(224, 224, 224, 0.808); 
 box-shadow: 0px 72px 49px -51px rgba(136, 160, 255, 0.21); 
}
.swiper-horizontal{
  overflow: visible;
}
.r-buttons1{
position: relative;
gap: 1rem;
/* top: -4rem;  */
padding-top: 2rem;
right: 0;
}
.r-button2{
  font-size: 1.4rem;
  padding: 0.2rem 0.8rem;
  color: blue;
  border: none;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}
.r-buttons1>:nth-child(1){
  background-color: #EEEEFF;
}
.r-buttons1>:nth-child(2){
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
}
@media (max-width:640px) {
  .r-head{
      align-items: center;

  }

}


/* index css */
.paddings {
  padding: 1.5rem;
}

.innerWidth {
  width: 100%;
}
.flexCenter {
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flexColCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flexColStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.flexColEnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}



/* media queries */
@media (min-width: 1536px) {
  .innerWidth {
    max-width: 1280px;
    margin: auto;
  }
}

@media (min-width: 640px) {
  .paddings {
    padding: 4rem;
  }
}

@media (min-width: 1280px), (min-width: 768px) {
  .paddings {
    padding: 2rem;
  }
}

@media (max-width: 640px) {
  .primaryText {
    font-size: 1.5rem;
  }
  .orangeText {
    font-size: 1.2rem;
  }
}
